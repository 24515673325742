const price = {
  car: {
    title: "Passenger car",
    price: 450
  },
  van: {
    title: "Commercial transport / minibus / minivan",
    price: 750
  },
  tir: {
    title: "Cargo transport",
    price: 1200
  }
};

export default price;